<template>
  <auth-container>
    <h1 class="mb-3 text-center font-semibold text-black font-roboto text-xlg">
      {{ $t("password_change") }}
    </h1>
    <h3 class="text-grey-fontgrey w-96 text-center mb-9">
      {{ $t("password_change_text") }}
    </h3>
    <div class="w-96 flex flex-row justify-start items-center mb-7">
      <avatar-bubble
        :backgroundColor="'bg-primary'"
        :textColor="'text-white'"
        class="mr-4"
        :name="
          state.user.email
            ? state.user.email
                .split('@')[0]
                .split('.')
                .join(' ')
            : ''
        "
        :size="'9'"
      />
      <h3 class="text-black">{{ state.user.email }}</h3>
    </div>
    <input-text
      data-testId="new-password"
      class="mb-5 w-96"
      :name="'password'"
      :id="'password'"
      :type="'password'"
      v-model="state.user.password"
      :label="$t('password_new')"
      :labelClass="'bg-white'"
      @keyup.enter="CHANGE_PASSWORD()"
    />
    <input-text
      data-testId="new-password-confirmation"
      class="mb-5 w-96"
      :name="'password_confirmation'"
      :id="'password_confirmation'"
      :type="'password'"
      v-model="state.user.password_confirmation"
      :label="$t('password_repeat')"
      :labelClass="'bg-white'"
      @keyup.enter="CHANGE_PASSWORD()"
    />

    <custom-button
      data-testId="change-password-button"
      @click="CHANGE_PASSWORD()"
      class="h-11 w-96 "
      :text="$t('password_change')"
      :bgColor="'bg-primary'"
      :textColor="'text-white'"
      :id="'change-password-button'"
    />
  </auth-container>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import AvatarBubble from "../../components/global/AvatarBubble.vue";
import CustomButton from "../../components/input/CustomButton.vue";
import InputText from "../../components/input/InputText.vue";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import AuthContainer from "./helpers/AuthContainer.vue";
export default defineComponent({
  name: "ChangePassword",
  components: {
    AuthContainer,
    InputText,
    AvatarBubble,
    CustomButton,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      user: {
        fullname: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    });
    /**
     * functions
     */
    async function CHANGE_PASSWORD() {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "change-password-button"
      );
      state.user.token = route.query.token;
      await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.CHANGE_PASSWORD}`,
        {
          password: state.user.password,
          password_confirmation: state.user.password_confirmation,
          token: route.query.token,
        }
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "change-password-button"
      );
    }
    // token from url
    async function FETCH_CHANGE_PASSWORD_USERINFORMATION() {
      const response = await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_CHANGE_PASSWORD_USERINFORMATION}`,
        route.query.token
      );
      state.user.fullname = response.fullname;
      state.user.email = response.email;
    }

    /**
     *  Created
     */

    store.commit(
      `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
      "isLoadingData"
    );
    Promise.all([FETCH_CHANGE_PASSWORD_USERINFORMATION()]).then(() => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "isLoadingData"
      );
    });

    return {
      state,
      CHANGE_PASSWORD,
    };
  },
});
</script>

<style></style>
